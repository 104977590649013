import React from "react"
import { graphql } from "gatsby"
import Layout from "@src/components/layout"
import Content from "@src/components/content"
import { PropTypes } from "prop-types"

const LandingPage = ({ data, location }) => {
  return (
    <Layout
      bannerMode={
        data.page.hero && data.page.hero.bannerMode
          ? data.page.hero.bannerMode
          : false
      }
      title={data.page.title}
      heroImage={
        data.page.hero && data.page.hero.heroBackgroundImage
          ? data.page.hero.heroBackgroundImage.fluid
          : null
      }
      heroVideo={data.page.hero ? data.page.hero.heroBackgroundVideo : null}
      heroText={data.page.hero ? data.page.hero.heroText : ""}
      heroTextPosition={
        data.page.hero ? data.page.hero.heroTextPosition : "center"
      }
      seoTitle={data.page.hero ? data.page.hero.seoTitle : null}
      strapline={data.page.hero ? data.page.hero.strapline : null}
      backgroundColour={
        data.page.hero && data.page.hero.backgroundColour
          ? data.page.hero.backgroundColour
          : null
      }
      linkText={data.page.hero ? data.page.hero.linkText : null}
      linkSlug={data.page.hero ? data.page.hero.linkSlug : null}
      metaTitle={data.page.seo ? data.page.seo.title : null}
      metaDescription={data.page.seo ? data.page.seo.description : null}
      metaImage={
        data.page.seo && data.page.seo.image ? data.page.seo.image.url : null
      }
      twitterCard={
        data.page.seo && data.page.seo.twitterCard
          ? data.page.seo.twitterCard
          : null
      }
      pageBackgroundColour={data.page.pageBackgroundColour}
    >
      <Content
        contentItems={data.page.content}
        location={location}
        pageTitle={data.page.title}
      />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.instanceOf(Array),
      hero: PropTypes.shape({
        bannerMode: PropTypes.bool,
        heroText: PropTypes.string.isRequired,
        heroTextPosition: PropTypes.string.isRequired,
        heroBackgroundImage: PropTypes.instanceOf(Object),
        heroBackgroundVideo: PropTypes.instanceOf(Object),
        title: PropTypes.string,
        seoTitle: PropTypes.string,
        strapline: PropTypes.string,
        backgroundColour: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.object,
        ]),
        linkText: PropTypes.string,
        linkSlug: PropTypes.string,
      }),
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        twitterCard: PropTypes.string,
      }),
      pageBackgroundColour: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    }),
  }).isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
}

export default LandingPage

export const query = graphql`
  query($slug: String!) {
    page: datoCmsLandingPage(slug: { eq: $slug }) {
      title
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
      pageBackgroundColour {
        rgb
      }
      content {
        ... on DatoCmsFeatureBlock {
          id
          __typename
          seoTitle
          title
          copy
          divider
          features {
            id
            image {
              gatsbyImageData(width: 100)
            }
            title
            copy
            slugText
            slug
          }
        }
        ... on DatoCmsLogoBlock {
          id
          __typename
          seoTitle
          title
          copy
          logos {
            id
            title
            images {
              gatsbyImageData(width: 768)
              alt
              format
              url
              originalId
            }
          }
        }
        ... on DatoCmsStatBlock {
          id
          __typename
          seoTitle
          seoTitleColor {
            rgb
          }
          title
          copy
          divider
          stats {
            id
            statNumber
            statCharacter
            statCopy
          }
        }
        ... on DatoCmsServicePillarsBlock {
          id
          __typename
          seoTitle
          title
          copy
          divider
          servicePillarList {
            id
            servicePillarLinks {
              id
              text
              slug
            }
            title
          }
        }
        ... on DatoCmsContactForm {
          id
          __typename
          title
          image {
            gatsbyImageData(width: 722)
            alt
            format
            url
          }
          text
        }
        ... on DatoCmsInteractiveInfographicBlock {
          id
          __typename
          leftColumnTitle
          rightColumnTitle
          leftColumnItems {
            id
            url
            title
            image {
              gatsbyImageData(width: 400)
            }
            imagePosition
          }
          rightColumnItems {
            id
            url
            title
            image {
              gatsbyImageData(width: 400)
            }
            imagePosition
          }
        }
        ... on DatoCmsInfographicBlock {
          id
          __typename
          infographic {
            id
            desktopImage {
              url
              alt
              title
            }
            mobileImage {
              url
              alt
              title
            }
          }
        }
        ... on DatoCmsVideoBlock {
          id
          __typename
          video {
            id
            height
            title
            width
            url {
              height
              width
              url
              title
              thumbnailUrl
              providerUid
              provider
            }
          }
        }
        ... on DatoCmsShareList {
          id
          __typename
          title
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
        ... on DatoCmsInsightList {
          id
          __typename
          title
          subTitle
          backgroundColour {
            rgb
          }
        }
        ... on DatoCmsTestimonialBlock {
          id
          __typename
          testimonial {
            id
            quote
            name
            role
            company
          }
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
        ... on DatoCmsProductList {
          id
          __typename
          title
          subTitle
          intro
          products {
            id
            title
            text
            slug
            image {
              gatsbyImageData(width: 768)
            }
            icon {
              gatsbyImageData(width: 125, layout: FIXED)
            }
          }
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
        ... on DatoCmsPromotedList {
          id
          __typename
          promoted {
            id
            title
            text
            slug
            image {
              gatsbyImageData(width: 768)
            }
          }
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
        ... on DatoCmsJobVacancyList {
          id
          __typename
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          title
          subTitle
          vacancies {
            id
            location
            title
            inBrief
            slug
          }
        }
        ... on DatoCmsUniqueList {
          id
          __typename
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          headline
          uniques {
            id
            title
            inBrief
          }
        }
        ... on DatoCmsHeadline {
          id
          __typename
          topLine
          subtext
        }
        ... on DatoCmsGallery {
          id
          __typename
          title
          subTitle
          intro
          body
          showLabels
          images {
            gatsbyImageData(width: 768)
            alt
            format
            url
          }
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          preferredNumberOfColumns
        }
        ... on DatoCmsSnapshot {
          id
          __typename
          preferredNumberOfColumns
          snapshotFacts {
            id
            headline
            strapline
            image {
              gatsbyImageData(width: 200)
              alt
            }
          }
          textAlign
          title
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
        ... on DatoCmsEditorial {
          id
          __typename
          fullWidth
          icon {
            gatsbyImageData(width: 125, layout: FIXED)
          }
          body
          videoBlock: video {
            id
            video {
              id
              url {
                url
                title
                provider
                providerUid
                thumbnailUrl
                width
                height
              }
              title
              height
              width
            }
          }
          sidebarImage {
            gatsbyImageData(width: 768)
            alt
            format
            url
            customData
          }
          gutterImage {
            gatsbyImageData(width: 1920)
            alt
          }
          layout
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          ctaText
          ctaUrl
        }
        ... on DatoCmsServiceList {
          id
          __typename
          layout
          arrowLinksLandingPageSlug
          arrowLinksGoToLandingPage
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          headline
          services {
            id
            title
            inBrief
            slug
            content {
              ... on DatoCmsHeadline {
                id
                __typename
                topLine
                subtext
              }
              ... on DatoCmsGallery {
                id
                __typename
                body
                images {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                }
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                preferredNumberOfColumns
              }
              ... on DatoCmsSnapshot {
                id
                __typename
                preferredNumberOfColumns
                snapshotFacts {
                  id
                  headline
                  strapline
                  image {
                    gatsbyImageData(width: 200)
                    alt
                  }
                }
                textAlign
                title
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
              ... on DatoCmsEditorial {
                id
                __typename
                fullWidth
                icon {
                  gatsbyImageData(width: 125, layout: FIXED)
                }
                body
                videoBlock: video {
                  id
                  video {
                    id
                    url {
                      url
                      title
                      provider
                      providerUid
                      thumbnailUrl
                      width
                      height
                    }
                    title
                    height
                    width
                  }
                }
                sidebarImage {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                  customData
                }
                gutterImage {
                  gatsbyImageData(width: 1920)
                  alt
                }
                layout
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                ctaText
                ctaUrl
              }
              ... on DatoCmsTeam {
                id
                title
                preferredNumberOfColumns
                teamMembers {
                  id
                  name
                  profileImage {
                    gatsbyImageData(width: 768)
                  }
                  metaInformation
                  quickBio
                }
                backgroundColour {
                  rgb
                }
                __typename
              }
              ... on DatoCmsCallToAction {
                id
                __typename
                largeMode
                actionText
                linkTo
                title
                subTitle
                intro
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
            }
          }
        }
        ... on DatoCmsPartnerList {
          id
          __typename
          layout
          arrowLinksLandingPageSlug
          arrowLinksGoToLandingPage
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          headline
          partners {
            id
            title
            slug
            logo {
              gatsbyImageData(width: 700)
            }
            briefSynopsys
            content {
              ... on DatoCmsHeadline {
                id
                __typename
                topLine
                subtext
              }
              ... on DatoCmsGallery {
                id
                __typename
                body
                images {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                }
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                preferredNumberOfColumns
              }
              ... on DatoCmsSnapshot {
                id
                preferredNumberOfColumns
                __typename
                snapshotFacts {
                  id
                  headline
                  strapline
                  image {
                    gatsbyImageData(width: 200)
                    alt
                  }
                }
                textAlign
                title
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
              ... on DatoCmsEditorial {
                id
                __typename
                fullWidth
                icon {
                  gatsbyImageData(width: 125, layout: FIXED)
                }
                body
                videoBlock: video {
                  id
                  video {
                    id
                    url {
                      url
                      title
                      provider
                      providerUid
                      thumbnailUrl
                      width
                      height
                    }
                    title
                    height
                    width
                  }
                }
                sidebarImage {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                  customData
                }
                gutterImage {
                  gatsbyImageData(width: 1920)
                  alt
                }
                layout
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                ctaText
                ctaUrl
              }
              ... on DatoCmsTeam {
                id
                title
                preferredNumberOfColumns
                teamMembers {
                  id
                  name
                  profileImage {
                    gatsbyImageData(width: 768)
                  }
                  metaInformation
                  quickBio
                }
                backgroundColour {
                  rgb
                }
                __typename
              }
              ... on DatoCmsCallToAction {
                id
                __typename
                largeMode
                actionText
                linkTo
                title
                subTitle
                intro
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
            }
          }
        }
        ... on DatoCmsTeam {
          id
          title
          preferredNumberOfColumns
          teamMembers {
            id
            name
            profileImage {
              gatsbyImageData(width: 768)
            }
            metaInformation
            quickBio
          }
          backgroundColour {
            rgb
          }
          __typename
        }
        ... on DatoCmsCaseStudyList {
          id
          title
          caseStudies {
            id
            clientName
            title
            briefSynopsys
            slug
            clickbait {
              gatsbyImageData(width: 768)
            }
            content {
              ... on DatoCmsHeadline {
                id
                __typename
                topLine
                subtext
              }
              ... on DatoCmsGallery {
                id
                __typename
                body
                images {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                }
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                preferredNumberOfColumns
              }
              ... on DatoCmsSnapshot {
                id
                __typename
                preferredNumberOfColumns
                snapshotFacts {
                  id
                  headline
                  strapline
                  image {
                    gatsbyImageData(width: 200)
                    alt
                  }
                }
                textAlign
                title
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
              ... on DatoCmsEditorial {
                id
                __typename
                fullWidth
                icon {
                  gatsbyImageData(width: 125, layout: FIXED)
                }
                body
                videoBlock: video {
                  id
                  video {
                    id
                    url {
                      url
                      title
                      provider
                      providerUid
                      thumbnailUrl
                      width
                      height
                    }
                    title
                    height
                    width
                  }
                }
                sidebarImage {
                  gatsbyImageData(width: 768)
                  alt
                  format
                  url
                  customData
                }
                gutterImage {
                  gatsbyImageData(width: 1920)
                  alt
                }
                layout
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
                ctaText
                ctaUrl
              }
              ... on DatoCmsTeam {
                id
                title
                preferredNumberOfColumns
                teamMembers {
                  id
                  name
                  profileImage {
                    gatsbyImageData(width: 768)
                  }
                  metaInformation
                  quickBio
                }
                backgroundColour {
                  rgb
                }
                __typename
              }
              ... on DatoCmsCallToAction {
                id
                __typename
                largeMode
                actionText
                linkTo
                title
                subTitle
                intro
                backgroundColour {
                  rgb
                }
                textColour {
                  rgb
                }
              }
            }
          }
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
          __typename
        }
        ... on DatoCmsCallToAction {
          id
          __typename
          largeMode
          actionText
          linkTo
          title
          subTitle
          intro
          backgroundColour {
            rgb
          }
          textColour {
            rgb
          }
        }
      }
      hero {
        bannerMode
        heroText
        heroTextPosition
        heroBackgroundImage {
          fluid(maxWidth: 1920, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsSizes
          }
        }
        heroBackgroundVideo {
          url
        }
        title
        seoTitle
        strapline
        backgroundColour {
          rgb
        }
        linkText
        linkSlug
      }
    }
  }
`
